import {
  FilterIcon,
  InformationCircleIcon,
  PencilIcon,
  SaveIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import Sidebar from "../../components/Sidebar";
import Alarmlist from "../../interfaces/Alarmlist";
import User from "../../interfaces/User";
import dataService from "../../services/data.service";

function EditAlarmlist() {
  const { orgaId, alarmlistId } = useParams();
  const [loading, setLoading] = useState(true);
  const [alarmlist, setAlarmlist] = useState<Alarmlist>();
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [availableUsers, setAvailableUsers] = useState<User[]>([]);
  const [search, setSearch] = useState("");
  const [selectedIds, setSelectedIds] = useState<String[]>([]);

  let navigate = useNavigate();

  async function fetchData() {
    const allUsers = await dataService.getUsers(orgaId ?? "");
    const currentAlarmlist = await dataService.getAlarmlist(
      orgaId ?? "",
      alarmlistId ?? ""
    );

    //Get A List of selectable Users
    if (allUsers.status === 200) {
      allUsers.data.forEach((user: User) => {
        setAllUsers((prevState) => [...prevState, user]);
        setAvailableUsers((prevState) => [...prevState, user]);
      });
    } else {
      alert("Fehler beim Laden (Fehler: " + allUsers.status + ")");
    }

    //Get Current Alarmlist
    if (currentAlarmlist.status === 200) {
      setAlarmlist(currentAlarmlist.data);
      currentAlarmlist.data.UserOnAlarmlist.forEach((entry: any) => {
        console.log(entry);
        setSelectedIds((prevState) => [
          ...prevState,
          (entry.userId ??= "test"),
        ]);
      });
    } else {
      alert("Fehler beim Laden (Fehler: " + currentAlarmlist.status + ")");
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const filter = (e: any) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      const results = allUsers.filter((user) => {
        return (
          user.firstname.toLowerCase().includes(keyword.toLowerCase()) ||
          user.lastname.toLowerCase().includes(keyword.toLowerCase()) ||
          user.username.toLowerCase().includes(keyword.toLowerCase())
        );
      });

      setAvailableUsers(results);
    } else {
      setAvailableUsers(allUsers);
    }

    setSearch(keyword);
  };

  function deleteAlarmlist() {
    let deleteConfirm = window.confirm("Alarmliste löschen?");
    if (deleteConfirm) {
      dataService.deleteAlarmlist(alarmlistId ?? "");
      toast.success("Erfolgreich gelöscht");
      navigate("../alarmlist");
    }
  }

  async function updateAlarmlist(pName: string, pUserIds: String[]) {
    if (alarmlist) {
      setLoading(true);

      const updated: Alarmlist = { ...alarmlist, name: pName };
      console.log(updated);

      let res = await dataService.updateAlarmlist(updated, pUserIds);
      if (res.status === 201) {
        setLoading(false);
        navigate("../alarmlist");
        toast.success("Erfolgreich aktualisiert");
      } else {
        toast.error("Ein Fehler ist aufgtreten");
      }
    }
  }

  const fieldStyling =
    "w-full px-3 py-2 border border-slate-200 rounded-md mt-2 focus:border-slate-500 transition-all";

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-grow justify-center">
        <div className="mt-12 w-2/3">
          {loading ? (
            <Loading />
          ) : (
            <>
              <div className="flex items-center justify-between">
                <h1 className="flex text-4xl">
                  <PencilIcon className="mr-3 w-6 text-slate-400" />
                  {alarmlist?.name} bearbeiten
                </h1>
              </div>
              <div className="mx-16 mt-16">
                <Formik
                  initialValues={{
                    name: alarmlist?.name,
                    userIds: selectedIds,
                  }}
                  onSubmit={(values) =>
                    updateAlarmlist(values.name ?? "Fehler", values.userIds)
                  }
                >
                  <Form>
                    <div className="mt-12 mb-2 flex items-center">
                      <p className=" mr-6 text-xl font-semibold text-slate-700">
                        1. Allgemeine Informationen
                      </p>
                      <hr className="flex-grow" />
                    </div>
                    <label className="col-span-2 my-3 block">
                      <span className="text-xs font-bold text-slate-500">
                        Name*
                      </span>
                      <Field
                        required
                        autocomplete="alarmlist_name"
                        className={fieldStyling}
                        type="name"
                        name="name"
                        placeholder="Alarmliste #1"
                      />
                      <ErrorMessage
                        component="p"
                        className="mt-2 text-xs font-semibold text-red-400"
                        name="firstname"
                      />
                    </label>
                    <div className="mt-12 mb-2 flex items-center">
                      <p className=" mr-6 text-xl font-semibold text-slate-700">
                        2. Helferauswahl
                      </p>
                      <hr className="flex-grow" />
                    </div>
                    <label className="col-span-2 my-3 block">
                      <div className="flex grid-cols-12 items-center gap-4 align-middle">
                        <label className="col-span-2 col-span-8 my-3 block">
                          <Field
                            className={fieldStyling}
                            placeholder="Suche"
                            value={search}
                            onChange={filter}
                          />
                        </label>
                      </div>
                      <div className="flex flex-col">
                        <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div className="overflow-hidden shadow-md sm:rounded-lg">
                              <table className="min-w-full ">
                                <thead className="bg-zinc-100 ">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
                                    ></th>
                                    <th
                                      scope="col"
                                      className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
                                    >
                                      Name
                                    </th>
                                    <th
                                      scope="col"
                                      className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
                                    >
                                      Qualifikationen
                                    </th>
                                    <th
                                      scope="col"
                                      className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
                                    >
                                      Führerschein
                                    </th>
                                    <th
                                      scope="col"
                                      className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
                                    >
                                      Alter
                                    </th>
                                    <th
                                      scope="col"
                                      className="relative py-3 px-6"
                                    >
                                      <span className="sr-only">Info</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="">
                                  {availableUsers.map((user) => {
                                    let birthDate = new Date(
                                      user.birthyear ?? 0,
                                      user.birthmonth + 1 ?? 0,
                                      user.birthday ?? 0
                                    );

                                    let ageString = "";
                                    let driversLicenses = "";

                                    user.DriverslicenseOnUsers?.forEach(
                                      (element) => {
                                        driversLicenses +=
                                          element.driverslicense.short + ", ";
                                      }
                                    );

                                    if (user.birthday) {
                                      let difference =
                                        Date.now() - birthDate.getTime();
                                      let ageDate = new Date(difference);
                                      let calculatedAge = Math.abs(
                                        ageDate.getUTCFullYear() - 1970
                                      );

                                      ageString =
                                        calculatedAge +
                                        " (" +
                                        user.birthday +
                                        "." +
                                        user.birthmonth +
                                        "." +
                                        user.birthyear +
                                        ")";
                                    } else {
                                      ageString = "Kein Alter";
                                    }

                                    return (
                                      <tr
                                        className="border-b bg-white odd:bg-white even:bg-gray-50"
                                        key={user.id}
                                      >
                                        <td className="whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900">
                                          <Field
                                            type="checkbox"
                                            name="userIds"
                                            value={user.id}
                                          />
                                        </td>
                                        <td className="whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900 ">
                                          {user.lastname}
                                          {", "}
                                          <span className="text-slate-500">
                                            {user.firstname}
                                          </span>
                                        </td>
                                        <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 ">
                                          -
                                        </td>
                                        <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 ">
                                          {driversLicenses}
                                        </td>
                                        <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 ">
                                          {ageString}
                                        </td>
                                        <td className="whitespace-nowrap py-4 px-6 text-right text-sm font-medium">
                                          <a
                                            title={
                                              user.firstname +
                                              " " +
                                              user.lastname +
                                              " anzeigen"
                                            }
                                            href="https://google.com"
                                            className="text-blue-500 transition-all  hover:text-blue-900"
                                          >
                                            <InformationCircleIcon className="h-4 w-4" />
                                          </a>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </label>
                    <p className="text-sm text-slate-400">
                      Erstellt am{" "}
                      {new Date(alarmlist?.createdAt ?? "").toLocaleString()} -
                      Zuletzt bearbeitet:{" "}
                      {new Date(alarmlist?.updatedAt ?? "").toLocaleString()}
                    </p>
                    <div className="flex justify-between">
                      <button
                        type="submit"
                        className="self-right my-6 flex items-center rounded-md bg-sky-700 px-6 py-2 text-sm text-white transition-all hover:bg-sky-800 hover:shadow-xl active:bg-sky-900"
                      >
                        <SaveIcon className="mr-2 w-5" /> Speichern
                      </button>
                      <button
                        onClick={() => deleteAlarmlist()}
                        className="active:bg-sredky-900 self-right my-6 flex items-center rounded-md bg-red-700 px-6 py-2 text-sm text-white transition-all hover:bg-red-800 hover:shadow-xl"
                      >
                        <TrashIcon className="mr-2 w-5" /> Löschen
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default EditAlarmlist;
