import {
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  LogoutIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import Orga from "../interfaces/Orga";
import authService from "../services/auth.service";
import dataService from "../services/data.service";

function Profile() {
  const [response, setResponse] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  async function fetchData() {
    setLoading(true);
    setResponse([]);

    const response = await dataService.getOrgas();
    response.data.forEach((element: any) => {
      setResponse((prevState) => [...prevState, element]);
    });

    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="sticky top-0 z-50 flex h-12 w-full items-center justify-center bg-white text-center align-middle shadow">
        Einsatz<span className="text-red-500">Systeme</span>
      </div>
      <div className="container mx-auto px-4 py-4">
        <span
          onClick={() => navigate("../orga")}
          className="flex cursor-pointer align-middle text-slate-500"
        >
          <ChevronLeftIcon className="w-5" /> Zurück
        </span>

        <div className="my-4">
          <h2 className="text-2xl">Account</h2>
          <hr />
          <div className="my-4 grid w-fit grid-cols-2 gap-y-2">
            <p className="text-sm text-slate-500">Name, Vorname</p>
            <p>
              {authService.getCurrentUser()?.lastname},{" "}
              {authService.getCurrentUser()?.firstname}
            </p>
            <p className="text-sm text-slate-500">E-Mail</p>
            <p className="">{authService.getCurrentUser()?.email}</p>
            <p className="text-sm text-slate-500">Straße, Hnr.</p>
            <p>
              {authService.getCurrentUser()?.street}{" "}
              {authService.getCurrentUser()?.house_nr}
            </p>
            <p className="text-sm text-slate-500">PLZ, Stadt</p>
            <p className="">
              {authService.getCurrentUser()?.zip}{" "}
              {authService.getCurrentUser()?.city}
            </p>
          </div>
          <div className="my-4 grid w-fit grid-cols-2 gap-x-4 text-sm text-slate-400">
            <p>Nutzer registriert seit:</p>
            <p>
              {new Date(
                authService.getCurrentUser()?.createdAt!
              ).toLocaleString()}
            </p>
            <p>Zuletzt aktualisiert:</p>
            <p>
              {new Date(
                authService.getCurrentUser()?.updatedAt!
              ).toLocaleString()}
            </p>
          </div>
        </div>
        <div className="my-6">
          <h2 className="text-2xl">Organisationsmitgliedschaften</h2>
          <hr />
          {loading ? (
            <Loading />
          ) : (
            <div className="my-4">
              {response.map((orga) => {
                let trailingElement;

                if (orga.state === "accepted") {
                  return (
                    <div className="cursor-pointer rounded bg-white px-6 py-3 hover:shadow-md">
                      <div className="flex items-center justify-between">
                        <p className="text-xl">{orga.organization.name}</p>
                        <div className="flex gap-2">
                          <div className="rounded bg-green-100 py-2 px-4 text-xs text-green-600">
                            Angenommen
                          </div>
                          <ChevronRightIcon className="w-5 text-slate-500" />
                        </div>
                      </div>
                    </div>
                  );
                } else if (orga.state === "pending") {
                  return (
                    <div className="rounded bg-white px-6 py-3">
                      <div className="flex items-center justify-between">
                        <p className="text-xl">{orga.organization.name}</p>
                        <div className="flex items-center gap-2 align-middle">
                          <CheckIcon className="mx-2 h-5 text-green-500" />
                          <XIcon className="mx-2 h-5 text-red-500" />
                          <div className="rounded bg-amber-100 py-2 px-4 text-xs text-amber-600">
                            Ausstehend
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else if (orga.state === "declined") {
                  return (
                    <div className="rounded bg-white px-6 py-3">
                      <div className="flex items-center justify-between">
                        <p className="text-xl">{orga.organization.name}</p>{" "}
                        <div className="flex gap-2">
                          <span className="text-yellow-500">⚠</span>
                        </div>
                      </div>
                    </div>
                  );
                } else if (orga.state === "blocked") {
                  return (
                    <div className="rounded bg-white px-6 py-3">
                      <div className="flex items-center justify-between">
                        <p className="text-xl">{orga.organization.name}</p>{" "}
                        <div className="flex gap-2">
                          <span className="text-yellow-500">⚠</span>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </div>
        <div className="flex gap-4">
          <button
            onClick={() => authService.logout()}
            className="active:bg-sredky-900 self-right my-6 flex items-center rounded-md border px-6 py-2 text-sm hover:shadow active:shadow-md"
          >
            <LogoutIcon className="mr-2 w-5" /> Ausloggen
          </button>
          <button
            onClick={() => authService.logout()}
            className="active:bg-sredky-900 self-right my-6 flex items-center rounded-md bg-red-700 px-6 py-2 text-sm text-white transition-all hover:bg-red-800 hover:shadow-xl"
          >
            <TrashIcon className="mr-2 w-5" /> Nutzerkonto Löschen
          </button>
        </div>
      </div>
    </div>
  );
}

export default Profile;
