import { LoginIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";

function RegisterSuccess() {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex w-full flex-col justify-between px-6 pt-36 pb-12 md:px-24 lg:w-1/2">
        {/** Message */}
        <div>
          <h1 className="mb-2 text-3xl font-bold text-slate-800 lg:text-4xl">
            Fertig<span className="text-red-500">.</span>
          </h1>

          <p>
            Dein Konto wurde erfolgreich erstellt. <br />
            Du kannst dich jetzt mit den Daten anmelden.
          </p>
        </div>
        {/** Success SVG */}
        <img src="/success.svg" width={250} alt="Erfolgsbestätigung" />
        <div>Test</div>

        {/** Actions */}
        <button
          onClick={() => navigate("/login")}
          className="flex items-center justify-center gap-2 rounded border border-slate-300 px-24 py-2 text-slate-500 transition-all hover:shadow active:shadow-md"
        >
          <LoginIcon className="h-4 w-4" /> Zum Login
        </button>
      </div>
    </>
  );
}

export default RegisterSuccess;
