import { CheckIcon, KeyIcon } from "@heroicons/react/solid";
import { ErrorMessage, Field } from "formik";
import Navbar from "../../components/Navbar";
import { useFormikContext } from "formik";

interface RegisterStepProps {
  onForward: () => void;
  onBackward: () => void;
}

function RegisterStep2({ onForward, onBackward }: RegisterStepProps) {
  const { submitForm } = useFormikContext();

  const handleSubmit = () => submitForm();

  return (
    <>
      <div className="flex flex-col justify-between px-6 pt-36 pb-12 md:px-24 lg:w-1/2">
        {/** Titel */}
        <div className="mb-6">
          <h1 className="text-3xl font-bold text-slate-800 lg:text-4xl">
            Konto erstellen<span className="text-red-500">.</span>
          </h1>
          <div></div>
        </div>
        {/** Stepper & Form */}
        <div>
          <form className="grid grid-cols-6 gap-4">
            <div className="col-span-3 mb-3 flex w-full flex-col sm:col-span-3">
              <label className="mb-1 flex gap-2 text-sm text-slate-500">
                Vorname
              </label>
              <Field
                type="text"
                autoComplete="firstname"
                name="firstname"
                className="col-span-2 rounded border bg-white px-6 py-2"
                placeholder="Dein Vorname"
              />
              <ErrorMessage
                component="p"
                className="mt-2 text-xs font-semibold text-red-400"
                name="firstname"
              />
            </div>

            <div className="col-span-3 mb-3 flex w-full flex-col sm:col-span-3">
              <label className="mb-1 flex gap-2 text-sm text-slate-500">
                Nachname
              </label>
              <Field
                type="text"
                autoComplete="lastname"
                name="lastname"
                className="col-span-2 rounded border bg-white px-6 py-2"
                placeholder="Dein Nachname"
              />
              <ErrorMessage
                component="p"
                className="mt-2 text-xs font-semibold text-red-400"
                name="lastname"
              />
            </div>
            <div className="col-span-3 mb-3 flex w-full flex-col sm:col-span-3">
              <label className="mb-1 flex gap-2 text-sm text-slate-500">
                Geburtsdatum
              </label>
              <Field
                name="birthday"
                type="date"
                autoComplete="birthday"
                className="col-span-2 rounded border bg-white px-6 py-2"
                placeholder="Dein Geburtsdatum"
              />
              <ErrorMessage
                component="p"
                className="mt-2 text-xs font-semibold text-red-400"
                name="birthday"
              />
            </div>
            <div className="col-span-3 mb-3 flex w-full flex-col sm:col-span-3">
              <label className="mb-1 flex gap-2 text-sm text-slate-500">
                Nutzername
              </label>
              <Field
                type="text"
                autoComplete="username"
                name="username"
                className="col-span-2 rounded border bg-white px-6 py-2"
                placeholder="Dein Nutzername"
              />
              <ErrorMessage
                component="p"
                className="mt-2 text-xs font-semibold text-red-400"
                name="username"
              />
            </div>
            <div className="mb col-span-4 flex w-full flex-col sm:col-span-4">
              <label className="mb-1 flex gap-2 text-sm text-slate-500">
                Straße
              </label>
              <Field
                type="text"
                name="street"
                autoComplete="street-address"
                className="col-span-2 rounded border bg-white px-6 py-2"
                placeholder="Deine Straße"
              />
              <ErrorMessage
                component="p"
                className="mt-2 text-xs font-semibold text-red-400"
                name="street"
              />
            </div>
            <div className="mb col-span-2 flex w-full flex-col sm:col-span-2">
              <label className="mb-1 flex gap-2 text-sm text-slate-500">
                Hausnummer
              </label>
              <Field
                type="text"
                autoComplete="street-address"
                className="col-span-2 rounded border bg-white px-6 py-2"
                placeholder="Deine H-Nr."
                name="house_nr"
              />
              <ErrorMessage
                component="p"
                className="mt-2 text-xs font-semibold text-red-400"
                name="house_nr"
              />
            </div>
            <div className="col-span-2 mb-3 flex w-full flex-col sm:col-span-2">
              <label className="mb-1 flex gap-2 text-sm text-slate-500">
                PLZ
              </label>
              <Field
                type="text"
                autoComplete="zip"
                className="col-span-2 rounded border bg-white px-6 py-2"
                placeholder="Deine PLZ"
                name="zip"
              />
              <ErrorMessage
                component="p"
                className="mt-2 text-xs font-semibold text-red-400"
                name="zip"
              />
            </div>
            <div className="col-span-4 mb-3 flex w-full flex-col sm:col-span-4">
              <label className="mb-1 flex gap-2 text-sm text-slate-500">
                Stadt
              </label>
              <Field
                type="text"
                className="col-span-2 rounded border bg-white px-6 py-2"
                placeholder="Deine Stadt"
                name="city"
              />
              <ErrorMessage
                component="p"
                className="mt-2 text-xs font-semibold text-red-400"
                name="city"
              />
            </div>
            <div className="col-span-6 mb-3 flex w-full flex-col sm:col-span-4">
              <label className="mb-1 flex gap-2 text-sm text-slate-500">
                Telefonnummer
              </label>
              <Field
                type="phone"
                className="col-span-2 rounded border bg-white px-6 py-2"
                placeholder="Deine Telefonnummer"
                name="phone"
              />
              <ErrorMessage
                component="p"
                className="mt-2 text-xs font-semibold text-red-400"
                name="phone"
              />
            </div>
          </form>
        </div>
        {/** Actions */}
        <div className="">
          <div className="mt-6 grid gap-4 text-sm md:grid-cols-2">
            <button
              onClick={() => onBackward()}
              className="flex items-center  justify-center rounded border border-slate-300 px-24 py-2 text-slate-500 transition-all hover:shadow active:shadow-md"
            >
              Zurück
            </button>
            <button
              onClick={() => handleSubmit()}
              className="flex items-center justify-center gap-2 rounded bg-green-500 px-24 py-2 text-white transition-all hover:bg-green-600 hover:shadow active:bg-green-700 active:shadow-md"
            >
              Absenden <CheckIcon width={18} height={18} />
            </button>
          </div>
          <p className="mt-2 text-xs text-slate-500">
            Mit "Weiter" akzeptierst du die AGB und die Datenschutzerklärung von
            EinsatzSysteme.
          </p>
        </div>
      </div>
    </>
  );
}

export default RegisterStep2;
