import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Orga from "../../../interfaces/Orga";
import dataService from "../../../services/data.service";

function OrgaSettingsGeneral() {
  const { orgaId } = useParams();

  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState<Orga>();

  async function fetchData() {
    setLoading(true);
    let response = await dataService.getOrganization(orgaId ?? "");
    setOrganization(response.data[0] as Orga);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="col-span-12">
        <h3 className="text-2xl">{organization?.name}</h3>
        <hr />
      </div>
      <div className="mt-6 grid grid-cols-12 gap-y-4 bg-white px-6 py-3 shadow">
        <div className="col-span-12">
          <div className="col-span-12 text-slate-500">
            <h3 className="text-xl">Statistik</h3>
          </div>
        </div>
        <div className="col-span-3">
          <p className="text-sm font-bold text-slate-700">Helfer (Insgesamt)</p>
          <p>- PLATZHALTER -</p>
        </div>
        <div className="col-span-3">
          <p className="text-sm font-bold text-slate-700">
            Alarmierungen (Insgesamt)
          </p>
          <p>- PLATZHALTER -</p>
        </div>
        <div className="col-span-3">
          <p className="text-sm font-bold text-slate-700">
            Alarmierungen (Insgesamt)
          </p>
          <p>- PLATZHALTER -</p>
        </div>
      </div>
      <div className="mt-6 grid grid-cols-12 gap-y-4 bg-white px-6 py-3 shadow">
        <div className="col-span-12">
          <div className="col-span-12 text-slate-500">
            <h3 className="text-xl">Kontaktinformationen</h3>
          </div>
        </div>
        <div className="col-span-3">
          <p className="text-sm font-bold text-slate-700">Straße</p>
          <p>{organization?.street}</p>
        </div>
        <div className="col-span-2">
          <p className="text-sm font-bold text-slate-700">Hausnummer</p>
          <p>{organization?.house_nr}</p>
        </div>
        <div className="col-span-3">
          <p className="text-sm font-bold text-slate-700">Stadt</p>
          <p>{organization?.city}</p>
        </div>
        <div className="col-span-3">
          <p className="text-sm font-bold text-slate-700">Plz</p>
          <p>{organization?.zip}</p>
        </div>
      </div>
      <div className="mt-6 grid grid-cols-12 gap-y-4 bg-white px-6 py-3 shadow">
        <div className="col-span-12 text-slate-500">
          <h3 className="text-xl">Rechnungsinformationen</h3>
        </div>
        <div className="col-span-3">
          <p className="text-sm font-bold text-slate-700">Straße</p>
          <p>{organization?.invoice_street}</p>
        </div>
        <div className="col-span-2">
          <p className="text-sm font-bold text-slate-700">Hausnummer</p>
          <p>{organization?.invoice_house_nr}</p>
        </div>
        <div className="col-span-3">
          <p className="text-sm font-bold text-slate-700">Stadt</p>
          <p>{organization?.city}</p>
        </div>
        <div className="col-span-3">
          <p className="text-sm font-bold text-slate-700">Plz</p>
          <p>{organization?.zip}</p>
        </div>
      </div>
    </>
  );
}

export default OrgaSettingsGeneral;
