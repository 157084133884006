import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { useEffect, useRef, useState } from "react";
import AnimateHeight from "react-animate-height";

interface Props {
  title: React.ReactNode;
  open?: boolean;
  children: React.ReactNode;
}

export default function Collapsible({ title, open, children }: Props) {
  const [height, setHeight] = useState<any>(0);

  const ref = useRef<HTMLDivElement>(null);

  const handleFilterOpening = () => {
    setHeight((prevState: any) => (height === 0 ? "auto" : 0));
  };

  return (
    <>
      <div className="shadow-sm bg-white rounded-md  my-6">
        <div className="flex justify-between px-6 py-3">
          <p>{title}</p>
          <button className="w-6 h-6" onClick={handleFilterOpening}>
            {height === 0 ? <ChevronDownIcon /> : <ChevronUpIcon />}
          </button>
        </div>

        <div className="b-bottom overflow-hidden">
          <div>
            <AnimateHeight height={height} duration={250} easing="ease-in-out">
              <hr className="mb-2" />
              {children}
            </AnimateHeight>
          </div>
        </div>
      </div>
    </>
  );
}
