import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import * as yup from "yup";
import Copyclaim from "../components/Copyclaim";

function ForgotPassword() {
  const SignInSchema = yup.object().shape({
    email: yup
      .string()
      .email("Bitte geben Sie eine gültige E-Mail ein.")
      .required("Diese Feld darf nicht leer sein."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: SignInSchema,
    onSubmit: (values) => {
      console.log(values);
    },
    validateOnChange: true,
    validateOnBlur: true,
  });

  return (
    <div className="min-h-screen flex flex-col align-middle items-center justify-center">
      <div className="bg-white shadow-lg rounded-md py-6 px-8 w-96">
        <h1 className="text-3xl text-center">Passwort vergessen</h1>
        <form
          onSubmit={formik.handleSubmit}
          className="space-y-6 w-full h-full mt-6 flex flex-col"
        >
          <div className="flex flex-col w-full">
            <label className="text-sm uppercase font-bold text-slate-500">
              E-Mail
            </label>
            <input
              required
              autoComplete="email"
              className="w-full px-3 py-2 border border-slate-200 rounded-md mt-2 focus:border-slate-200 focus:border-2 appearance-none transition-all"
              placeholder="max@mustermann.de"
              value={formik.values.email}
              onChange={formik.handleChange}
              id="email"
            />
          </div>

          <div className="">
            <Link
              to="/login"
              className="my-3 text-sm text-sky-600 flex items-center w-full"
            >
              <ArrowLeftIcon className="w-4 mr-2" /> Zurück zum Login
            </Link>
            <button className="w-full bg-sky-700 text-white rounded-md py-2 px-3 hover:shadow-lg hover:bg-sky-800 transition-all">
              Absenden
            </button>
          </div>
        </form>
      </div>
      <Copyclaim />
    </div>
  );
}

export default ForgotPassword;
