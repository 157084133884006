import { CogIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import OrgaSettingsAAO from "./tabs/aao";
import OrgaSettingsGeneral from "./tabs/general";
import OrgaSettingsImportExport from "./tabs/import_export";
import OrgaSettingsPermissions from "./tabs/permissions";

enum Tab {
  general,
  aao,
  permissions,
}

function OrgaSettings() {
  const tabClass =
    "cursor-pointer border-b-2 hover:border-cyan-700 transition-all px-4 py-2 text-sm font-bold text-slate-600";
  const activeTabClass = tabClass + " border-cyan-700";

  const [selected, setSelected] = useState(0);

  const items = [
    <OrgaSettingsGeneral />,
    <OrgaSettingsPermissions />,
    <OrgaSettingsAAO />,
    <OrgaSettingsImportExport />,
  ];

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-grow justify-center">
        <div className="mt-12 w-2/3">
          <div className="flex items-center justify-between">
            <h1 className="flex text-4xl">
              <CogIcon className="mr-3 w-6 text-slate-400" />
              Einstellungen
            </h1>
          </div>
          <div className="mt-16">
            <div className="flex space-x-4">
              <button
                onClick={() => setSelected(0)}
                className={selected === 0 ? activeTabClass : tabClass}
              >
                Allgemein
              </button>
              <button
                onClick={() => setSelected(1)}
                className={selected === 1 ? activeTabClass : tabClass}
              >
                Benutzer & Rechte
              </button>
              <button
                onClick={() => setSelected(2)}
                className={selected === 2 ? activeTabClass : tabClass}
              >
                Alarmierung
              </button>
              <button
                onClick={() => setSelected(3)}
                className={selected === 3 ? activeTabClass : tabClass}
              >
                Import / Export
              </button>
            </div>
            <div className="mt-4">{items[selected]}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrgaSettings;
