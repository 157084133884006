import { ClipboardListIcon, PlusIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../../components/Loading";
import Sidebar from "../../components/Sidebar";
import Alarmlist from "../../interfaces/Alarmlist";
import authService from "../../services/auth.service";
import dataService from "../../services/data.service";

function Alarmlists() {
  const { orgaId } = useParams();
  const [loading, setLoading] = useState(true);
  const [alarmlists, setAlarmlists] = useState<Alarmlist[]>([]);

  useEffect(() => {
    dataService.getAlarmlists(orgaId ?? "").then((res) => {
      if (res.status === 200) {
        res.data.forEach((alarmlist: Alarmlist) => {
          setAlarmlists((prevState) => [...prevState, alarmlist]);
        });

        setLoading(false);
      } else {
        alert("Fehler beim Laden (Fehler: " + res.status + ")");
      }
    });
  }, []);

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-grow justify-center">
        <div className="w-2/3 mt-12">
          <div className="flex justify-between items-center">
            <h1 className="text-4xl flex">
              <ClipboardListIcon className="w-6 mr-3 text-slate-400" />
              Alarmlisten
            </h1>
            <Link
              to="createAlarmlist"
              className="bg-sky-700 text-white rounded-md px-6 py-2 flex items-center hover:shadow-xl hover:bg-sky-800 active:bg-sky-900 transition-all text-sm"
            >
              <PlusIcon className="w-5 mr-2" /> Neue Alarmliste
            </Link>
          </div>
          <div className="mt-16">
            {loading ? (
              <Loading />
            ) : (
              <>
                <p className="text-sm uppercase font-bold text-slate-500">
                  {alarmlists.length} Einträge
                </p>
                <div className="flex flex-col">
                  <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block py-2 min-w-full sm:px-6 lg:px-8">
                      <div className="overflow-hidden shadow-md sm:rounded-lg">
                        <table className="min-w-full">
                          <thead className="bg-zinc-100">
                            <tr>
                              <th
                                scope="col"
                                className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase"
                              >
                                Helfer
                              </th>
                              <th scope="col" className="relative py-3 px-6">
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {alarmlists.map((alarmlist) => {
                              return (
                                <tr className="bg-white border-b odd:bg-white even:bg-gray-50">
                                  <td className="py-4 px-6 text-sm font-medium text-gray-900 whitespace-nowrap ">
                                    {alarmlist.name}
                                  </td>
                                  <td className="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
                                    {alarmlist.UserOnAlarmlist.length +
                                      " Helfer"}
                                  </td>
                                  <td className="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
                                    <a
                                      className="text-blue-600 hover:text-blue-900"
                                      href={"alarmlist/" + alarmlist.id}
                                    >
                                      Bearbeiten
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Alarmlists;
