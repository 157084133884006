import { FilterIcon, PlusIcon, SaveIcon } from "@heroicons/react/solid";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../../../components/Loading";
import Sidebar from "../../../../components/Sidebar";
import Alarmlist from "../../../../interfaces/Alarmlist";
import dataService from "../../../../services/data.service";

function CreateAAO() {
  const { orgaId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [alarmlists, setAlarmlists] = useState<Alarmlist[]>([]);
  const [availableAlarmlists, setAvailableAlarmlists] = useState<Alarmlist[]>(
    []
  );
  const [search, setSearch] = useState("");

  async function fetchData() {
    dataService.getAlarmlists(orgaId ?? "").then((res) => {
      if (res.status === 200) {
        res.data.forEach((alarmlist: Alarmlist) => {
          setAlarmlists((prevState) => [...prevState, alarmlist]);
          setAvailableAlarmlists((prevState) => [...prevState, alarmlist]);
        });

        console.log(alarmlists);

        setLoading(false);
      } else {
        alert("Fehler beim Laden (Fehler: " + res.status + ")");
      }
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const filter = (e: any) => {
    const keyword = e.target.value;

    if (keyword === "") return setAvailableAlarmlists(alarmlists);

    const results = alarmlists.filter((alarmlist) => {
      return alarmlist.name.toLowerCase().includes(keyword.toLowerCase());
    });

    setAvailableAlarmlists(results);
    setSearch(keyword);
  };

  const checkAll = (e: any) => {
    const toggle = e.target.value;

    if (toggle) {
    }
  };

  const fieldStyling =
    "w-full px-3 py-2 border border-slate-200 rounded-md mt-2 focus:border-slate-500 transition-all";

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-grow justify-center">
        <div className="mt-12 w-2/3">
          <div className="flex items-center justify-between">
            <h1 className="flex text-4xl">
              <PlusIcon className="mr-3 w-6 text-slate-400" />
              Neue AAO
            </h1>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="mt-16">
              <Formik
                initialValues={{
                  name: "",
                  alarmlistIds: [],
                }}
                onSubmit={async (values) => {
                  setLoading(true);
                  await dataService.createAAO(
                    values.name,
                    orgaId ?? "",
                    values.alarmlistIds
                  );
                  toast.success("Erfolgreich erstellt");
                  navigate("../settings");
                }}
              >
                <Form>
                  <div className="mt-12 mb-2 flex items-center">
                    <p className="mr-6 text-xl font-semibold text-slate-700">
                      1. Allgemeine Informationen
                    </p>

                    <hr className="flex-grow" />
                  </div>
                  <label className="col-span-2 my-3 block">
                    <span className="text-xs font-bold text-slate-500">
                      Name*
                    </span>
                    <Field
                      required
                      autoComplete="alarmlist_name"
                      className={fieldStyling}
                      type="name"
                      name="name"
                      placeholder="AAO #1"
                    />
                    <ErrorMessage
                      component="p"
                      className="mt-2 text-xs font-semibold text-red-400"
                      name="firstname"
                    />
                  </label>
                  <div className="mt-12 mb-2 flex items-center">
                    <p className=" mr-6 text-xl font-semibold text-slate-700">
                      2. Helferauswahl
                    </p>
                    <hr className="flex-grow" />
                  </div>
                  <label className="col-span-2 my-3 block">
                    <div className="flex grid-cols-12 items-center gap-4 align-middle">
                      <label className="col-span-2 my-3 block">
                        <Field
                          className={fieldStyling}
                          placeholder="Suche"
                          value={search}
                          onChange={filter}
                        />
                      </label>
                    </div>
                    <div className="flex flex-col">
                      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                          <div className="overflow-hidden shadow-md sm:rounded-lg">
                            <table className="min-w-full ">
                              <thead className="bg-zinc-100 ">
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
                                  >
                                    <input
                                      type="checkbox"
                                      onChange={checkAll}
                                    ></input>
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
                                  >
                                    Name
                                  </th>
                                  <th
                                    scope="col"
                                    className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
                                  >
                                    Helfer
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="">
                                {availableAlarmlists.map((alarmlist) => {
                                  return (
                                    <tr
                                      className="border-b bg-white odd:bg-white even:bg-gray-50"
                                      key={alarmlist.id}
                                    >
                                      <td className="w-12 whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900">
                                        <Field
                                          type="checkbox"
                                          name="alarmlistIds"
                                          value={alarmlist.id}
                                        />
                                      </td>
                                      <td className="whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900 ">
                                        {alarmlist.name}
                                      </td>
                                      <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 ">
                                        {alarmlist.UserOnAlarmlist.length +
                                          " Helfer"}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                  <button
                    type="submit"
                    className="self-right my-16 flex items-center rounded-md bg-sky-700 px-6 py-2 text-sm text-white transition-all hover:bg-sky-800 hover:shadow-xl active:bg-sky-900"
                  >
                    <SaveIcon className="mr-2 w-5" /> Speichern
                  </button>
                </Form>
              </Formik>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateAAO;
