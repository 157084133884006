import axios from "axios";
import Alarmlist from "../interfaces/Alarmlist";
import User from "../interfaces/User";
import authHeader from "./auth-header";
import authService from "./auth.service";

const API_URL = process.env.REACT_APP_API_URL;

class DataService {
  getOrgas() {
    let _userId = authService.getCurrentUser()?.id;
    return axios.get(`${API_URL}/user/${_userId}/orgas`, {
      headers: authHeader(),
    });
  }

  getUsers(orgaId: string) {
    return axios.get(`${API_URL}/organization/${orgaId}/users`, {
      headers: authHeader(),
    });
  }

  registerUser(pUser: User, pInviteCode: string) {
    return axios.post(`${API_URL}/user/register/${pInviteCode}`, { ...pUser });
  }

  getUser(orgaId: string, userId: string) {
    return axios.get(`${API_URL}/user/${userId}`, { headers: authHeader() });
  }

  getAlarmlists(orgaId: string) {
    return axios.get(`${API_URL}/alarmlist/orga/${orgaId}`, {
      headers: authHeader(),
    });
  }

  getInviteCodeData(token: string) {
    return axios.get(`${API_URL}/invite/${token}`, {});
  }

  getAlarmlist(orgaId: string, alarmlistId: string) {
    return axios.get(`${API_URL}/alarmlist/orga/${orgaId}/${alarmlistId}`, {
      headers: authHeader(),
    });
  }

  deleteAlarmlist(alarmlistId: string) {
    return axios.delete(`${API_URL}/alarmlist/${alarmlistId}`, {
      headers: authHeader(),
    });
  }

  getAllAAO(orgaId: string) {
    return axios.get(`${API_URL}/aao`, { headers: authHeader() });
  }

  createAAO(pName: string, pOrgaId: string, pAlarmlistIds: Array<string>) {
    return axios.post(
      `${API_URL}/aao`,
      {
        orgaId: pOrgaId,
        description: "Test",
        name: pName,
        alarmlistIds: pAlarmlistIds,
      },
      {
        headers: authHeader(),
      },
    );
  }

  getOrganization(orgaId: string) {
    return axios.get(`${API_URL}/organization`, { headers: authHeader() });
  }

  createAlarmlist(pOrgaId: string, pName: string, pUserIds: Array<string>) {
    return axios.post(
      `${API_URL}/alarmlist/orga/${pOrgaId}`,
      {
        name: pName,
        userIds: pUserIds,
      },
      {
        headers: authHeader(),
      },
    );
  }

  updateAlarmlist(pAlarmlist: Alarmlist, pUsers: String[]) {
    return axios.patch(`${API_URL}/alarmlist/` + pAlarmlist.id, {
      ...pAlarmlist,
      userIds: pUsers,
    });
  }

  deleteRegisteredDevice(pDeviceId: string) {
    return axios.delete(`${API_URL}/registeredDevice/device/${pDeviceId}`, {
      headers: authHeader(),
    });
  }

  fetchRegisteredDevices(pUserId: string) {
    return axios.get(`${API_URL}/registeredDevice/user/${pUserId}`, {
      headers: authHeader(),
    });
  }

  removeUserFromOrganisation(pUserId: string, pOrgaId: string) {
    return axios.delete(`${API_URL}/organization/${pOrgaId}/users/${pUserId}`, {
      headers: authHeader(),
    });
  }

  sendAlarmToUser(pFCMToken: string) {
    return axios.post(
      `${API_URL}/registeredDevice/`,
      {
        fcmToken: pFCMToken,
      },
      { headers: authHeader() },
    );
  }

  getAAO(pAAOId: string) {
    return axios.get(`${API_URL}/aao/${pAAOId}`, {
      headers: authHeader(),
    });
  }

  getAllAlarms(pOrgaId: string) {
    return axios.get(`${API_URL}/organization/${pOrgaId}/alarms`, {
      headers: authHeader(),
    });
  }

  getAlarm(pAlarmId: string) {
    return axios.get(`${API_URL}/alarm/${pAlarmId}`, {
      headers: authHeader(),
    });
  }

  getUserCountOnAAO(pAaoId: string) {
    return axios.get(`${API_URL}/aao/${pAaoId}/count`, {
      headers: authHeader(),
    });
  }

  updateAAO(pAaoId: string, pName: string, pAlarmlistIds: string[]) {
    return axios.patch(
      `${API_URL}/aao/${pAaoId}`,
      {
        name: pName,
        alarmlistIds: pAlarmlistIds,
      },
      {
        headers: authHeader(),
      },
    );
  }

  deleteAlarm(pAlarmId: string) {
    return axios.delete(`${API_URL}/alarm/${pAlarmId}`, {
      headers: authHeader(),
    });
  }

  verifyUser(pOTP: string) {
    return axios.post(`${API_URL}/user/verify/`, { verify_code: pOTP });
  }

  inviteUser(pEmail: string, pOrgaId: string) {
    return axios.post(
      `${API_URL}/invite/`,
      { email: pEmail, orgaId: pOrgaId },
      {
        headers: authHeader(),
      },
    );
  }

  sendAlarm(pAaoId: string, pDescription: string) {
    return axios.post(
      `${API_URL}/aao/${pAaoId}/alarm`,
      {
        description: pDescription,
      },
      {
        headers: authHeader(),
      },
    );
  }
}

export default new DataService();
