import { ChevronLeftIcon } from "@heroicons/react/solid";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Copyclaim from "../components/Copyclaim";
import Loading from "../components/Loading";
import dataService from "../services/data.service";

function Verify() {
  const { otp } = useParams();

  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("");

  async function init() {
    setLoading(true);
    try {
      let res = await dataService.verifyUser(otp!);
      setText("Ihre E-Mail-Adresse wurde erfolgreich verifiziert.");
    } catch {
      setText("Es ist ein Fehler aufgetreten.");
    }
    setLoading(false);
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="container mx-auto py-12">
      <h1 className="text-3xl">Verifizierung</h1>
      {loading ? <Loading /> : <p className="text-slate-500">{text}</p>}
      <Link
        to={"../../login"}
        className="my-4 flex items-center justify-center rounded bg-sky-500 py-3 px-6 align-middle text-white shadow hover:shadow-md"
      >
        <ChevronLeftIcon className="ml-4 h-5 " /> Zurück zum Login
      </Link>
      <Copyclaim />
    </div>
  );
}

export default Verify;
