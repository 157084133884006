import { DocumentAddIcon, DocumentDownloadIcon } from "@heroicons/react/solid";

function OrgaSettingsImportExport() {
  return (
    <div>
      <div className="mb-6 rounded bg-white px-6 py-3 shadow">
        <p className="mb-4 text-lg">Helfer</p>
        <div className="flex gap-4">
          <button className="flex items-center justify-center rounded-md bg-sky-700 px-6 py-2 align-middle text-sm text-white transition-all hover:bg-sky-800 hover:shadow-xl active:bg-sky-900">
            <DocumentAddIcon className="mr-2 w-5" /> Helfer importieren
          </button>
          <button className="flex items-center justify-center rounded-md bg-sky-700 px-6 py-2 align-middle text-sm text-white transition-all hover:bg-sky-800 hover:shadow-xl active:bg-sky-900">
            <DocumentDownloadIcon className="mr-2 w-5" /> Helfer exportieren
          </button>
        </div>
      </div>
      <div className="rounded bg-white px-6 py-3 shadow">
        <p className="mb-4 text-lg">Alarmierungen</p>
        <div className="flex gap-4">
          <button className="flex items-center justify-center rounded-md bg-sky-700 px-6 py-2 align-middle text-sm text-white transition-all hover:bg-sky-800 hover:shadow-xl active:bg-sky-900">
            <DocumentDownloadIcon className="mr-2 w-5" /> Alarmierungen
            exportieren
          </button>
        </div>
      </div>
    </div>
  );
}

export default OrgaSettingsImportExport;
