import React, { useState } from "react";

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const [products, setProducts] = useState(false);

  return (
    <>
      <header className="fixed z-50 flex h-20 w-full items-center justify-center gap-4 border-b border-b-slate-100 bg-slate-50 bg-opacity-95 px-16 py-2 text-slate-500 backdrop-blur-md lg:justify-start">
        <h2 className="">
          Einsatz<span className="text-red-500">Systeme</span>
        </h2>

        <ul className="ml-12 hidden place-items-center gap-24 text-slate-400 lg:flex">
          <li className="cursor-pointer text-slate-500 transition-all hover:text-slate-700">
            <a href={process.env.LANDING_PAGE}>Start</a>
          </li>
        </ul>

        <div className="ml-auto hidden flex-row items-center gap-4 align-middle lg:flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
            />
          </svg>
          <div className="flex flex-col">
            <a
              href="tel:+4954819299642"
              className="font-semibold text-slate-500"
            >
              +49 (0) 5481 9299642
            </a>
            <p className="text-xs font-light text-slate-400">
              kostenlose 24h Hotline
            </p>
          </div>
        </div>
      </header>
      {/*Sidebar*/}
      <div
        className="fixed top-0 z-40 h-screen w-64 border-r bg-white px-4 pt-24 shadow-xl transition-all duration-500 ease-in-out"
        style={{
          left: sidebar ? "0" : "-20rem",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 cursor-pointer"
          fill="none"
          viewBox="0 0 24 24"
          onClick={() => setSidebar(!sidebar)}
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>

        <ul className="text-lg">
          <li className="my-4 cursor-pointer font-bold text-slate-700 transition-all hover:text-slate-700">
            Start
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navbar;
