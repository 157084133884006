import { CheckCircleIcon, LoginIcon, MailIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import Navbar from "../../components/Navbar";

function RegisterLoading() {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex w-full flex-col justify-between px-6 pt-36 pb-12 md:px-24 lg:w-1/2">
        {/** Message */}
        <div>
          <h1 className="mb-2 text-3xl font-bold text-slate-800 lg:text-4xl">
            Lade Daten<span className="text-red-500">.</span>
          </h1>
        </div>
        {/** Success SVG */}
        <Loading />

        {/** Actions */}
        <div></div>
      </div>
    </>
  );
}

export default RegisterLoading;
