import {
  BellIcon,
  ClockIcon,
  PlusIcon,
  TrashIcon,
  ViewListIcon,
} from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Collapsible from "../../components/Collapsible";
import Loading from "../../components/Loading";
import Sidebar from "../../components/Sidebar";
import Alarm from "../../interfaces/Alarm";
import dataService from "../../services/data.service";

function AlarmPage() {
  const { orgaId } = useParams();

  const [loading, setLoading] = useState(false);
  const [alarms, setAlarms] = useState<Alarm[]>([]);

  async function fetchAlarms() {
    setLoading(true);
    setAlarms([]);
    let response = await dataService.getAllAlarms(orgaId ?? "");

    if (!response || response.status !== 200)
      return toast.error("Fehler beim Laden der Alarmierungen");

    response.data.forEach((alarm: Alarm) => {
      setAlarms((prevState) => [...prevState, alarm]);
    });
    setLoading(false);
  }

  async function deleteAlarm(pAlarmId: string) {
    setLoading(true);
    let response = await dataService.deleteAlarm(pAlarmId);
    if (response.status !== 200)
      return toast.error("Konnte Alarm nicht löschen");
    toast.success("Alarm wurde erfolgreich gelöscht.");
    return fetchAlarms();
  }

  useEffect(() => {
    fetchAlarms();
  }, []);

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex flex-grow justify-center">
        <div className="mt-12 w-2/3">
          <div className="flex items-center justify-between">
            <h1 className="flex text-4xl">
              <BellIcon className="mr-3 w-6 text-red-400" />
              Alarmieren
            </h1>
            <Link
              to="createAlarm"
              className="flex items-center rounded-md bg-red-700 px-6 py-2 text-sm text-white transition-all hover:bg-red-800 hover:shadow-xl active:bg-red-900"
            >
              <PlusIcon className="mr-2 w-5" /> Alarmieren
            </Link>
          </div>
          <div className="mt-16">
            <div className="mt-12 mb-2 flex items-center">
              <p className=" mr-6 flex items-center text-xl font-semibold text-slate-700">
                <ClockIcon className="mr-4 w-4" /> Historie
              </p>
              <hr className="flex-grow" />
            </div>
            {loading ? (
              <Loading />
            ) : (
              alarms.map((alarm) => (
                <AlarmDetail alarm={alarm} deleteAlarm={deleteAlarm} />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

interface AlarmDetailProps {
  alarm: Alarm;
  deleteAlarm: (alarmId: string) => void;
}

function AlarmDetail({ alarm, deleteAlarm }: AlarmDetailProps) {
  const navigate = useNavigate();

  const labels = [
    "Ausstehend",
    "Abgelehnt",
    "< 60min",
    "< 30min",
    "< 15min",
    "Angekommen",
  ];
  console.log([
    alarm.AlarmResponse?.filter((res) => res.responded === false).length,
    alarm.AlarmResponse?.filter((res) => res.declined).length,
    alarm.AlarmResponse?.filter((res) => res.eta === "60min").length,
    alarm.AlarmResponse?.filter((res) => res.eta === "30min").length,
    alarm.AlarmResponse?.filter((res) => res.eta === "15min").length,
    alarm.AlarmResponse?.filter((res) => res.arrived).length,
  ]);

  const data = {
    labels,

    datasets: [
      {
        label: "Rückmeldungen",
        data: [
          alarm.AlarmResponse?.filter((res) => res.responded === false).length,
          alarm.AlarmResponse?.filter((res) => res.declined).length,
          alarm.AlarmResponse?.filter((res) => res.eta === "60min").length,
          alarm.AlarmResponse?.filter((res) => res.eta === "30min").length,
          alarm.AlarmResponse?.filter((res) => res.eta === "15min").length,
          alarm.AlarmResponse?.filter((res) => res.arrived).length,
        ],
        backgroundColor: [
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgb(255, 159, 64)",
          "rgb(255, 99, 132)",
          "rgb(75, 192, 192)",
          "rgb(75, 192, 192)",
          "rgb(75, 192, 192)",
          "rgb(75, 192, 192)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Collapsible
      title={
        <div className="flex flex-row items-center align-middle">
          {alarm.state === "open" && (
            <div
              title="Alarm ausgelöst"
              className="mr-2 h-2 w-2 cursor-help rounded-full bg-green-400"
            ></div>
          )}
          <p>
            {alarm.keyword}{" "}
            <span className="text-sm text-slate-400 ">
              - {new Date(alarm.createdAt).toLocaleString()}
            </span>
          </p>
        </div>
      }
    >
      <div className="px-6 py-3">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3">
          <div>
            <p className="text-sm text-slate-400">Stichwort</p>
            <p>{alarm.keyword}</p>
          </div>

          <div>
            <p className="text-sm text-slate-400">Beschreibung (Optional)</p>
            <p>{alarm.description}</p>
          </div>
          <div>
            <p className="text-sm text-slate-400">Status</p>
            <p>{alarm.state}</p>
          </div>
          <div>
            <p className="text-sm text-slate-400">Erstellt am</p>
            <p>{new Date(alarm.createdAt).toLocaleString()}</p>
          </div>
          <div>
            <p className="text-sm text-slate-400">Zuletzt aktualisiert</p>
            <p>{new Date(alarm.updatedAt).toLocaleString()}</p>
          </div>

          <div>
            <p className="text-sm text-slate-400">ID</p>
            <p>{alarm.id}</p>
          </div>
        </div>
      </div>

      <div className="px-6 py-3">
        <p className="text-lg text-slate-400">Rückmeldungen</p>
        <div className="my-4">
          <Bar data={data} options={options} height={64} />
        </div>
      </div>

      <div className="mt-4 flex h-12 items-center justify-start gap-4 bg-slate-50 px-6 py-3 align-middle">
        <button
          onClick={() => navigate(alarm.id)}
          className="flex items-center align-middle text-blue-500"
        >
          <ViewListIcon className="mr-2 h-5" /> Detailansicht
        </button>
        <button
          onClick={() => deleteAlarm(alarm.id)}
          className="flex items-center align-middle text-red-500"
        >
          <TrashIcon className="mr-2 h-5" /> Alarm löschen
        </button>
      </div>
    </Collapsible>
  );
}

export default AlarmPage;
