import {
  ClockIcon,
  MailIcon,
  PlusIcon,
  UserGroupIcon,
} from "@heroicons/react/solid";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import Sidebar from "../../components/Sidebar";
import User from "../../interfaces/User";
import dataService from "../../services/data.service";

function Users() {
  const { orgaId } = useParams();
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    dataService.getUsers(orgaId ?? "").then((res) => {
      if (res.status === 200) {
        res.data.forEach((user: User) => {
          setUsers((prevState) => [...prevState, user]);
        });
        setLoading(false);
      } else {
        alert("Fehler beim Laden (Fehler: " + res.status + ")");
      }
    });
  }, []);

  async function inviteUser() {
    setSubmitting(true);
    try {
      await dataService.inviteUser(inviteEmail, orgaId ?? "");
      toast.success("Einladung erfolgreich versendet.");
    } catch (e) {
      if (e && axios.isAxiosError(e)) {
        console.log(e);
        if (e.response!.status === 400) {
        } else if (e.response!.status === 409) {
          toast.warning("Nutzer bereits eingeladen");
        } else if (e.response!.status === 404) {
          toast.error("Nutzer nicht gefunden");
        } else {
          toast.error("Fehler beim Senden der Einladung");
        }
      }
    }
    setInviteEmail("");
    setModal(false);
    setSubmitting(false);
  }

  return (
    <>
      {modal ? (
        <>
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden outline-none focus:outline-none">
            <div className="relative my-6 mx-auto w-auto max-w-3xl">
              {/*content*/}
              <div className="relative flex w-full flex-col rounded-lg border-0 bg-white shadow-lg outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between rounded-t border-b border-solid border-slate-200 p-5">
                  <h3 className="text-xl font-semibold">Nutzer einladen</h3>
                  <button
                    className="float-right ml-auto border-0 bg-transparent p-1 text-3xl font-semibold leading-none text-black opacity-5 outline-none focus:outline-none"
                    onClick={() => setModal(false)}
                  ></button>
                </div>
                {/*body*/}
                <div className="relative flex-auto p-6">
                  <p className="my-4">Nutzer E-Mail eingeben</p>

                  <div className="flex flex-col">
                    <input
                      type="email"
                      className="rounded-lg border border-solid border-slate-200 p-2"
                      placeholder="E-Mail"
                      value={inviteEmail}
                      onChange={(e) => setInviteEmail(e.target.value)}
                    />
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end rounded-b border-t border-solid border-slate-200 p-6">
                  <button
                    className="background-transparent mr-1 mb-1 px-6 py-2 text-sm text-blue-500 outline-none transition-all duration-150 ease-linear focus:outline-none"
                    type="button"
                    onClick={() => setModal(false)}
                  >
                    Abbrechen
                  </button>
                  <button
                    onClick={() => inviteUser()}
                    className="self-right flex items-center rounded-md bg-blue-700 px-6 py-2 text-sm text-white transition-all hover:bg-blue-800 hover:shadow-xl active:bg-blue-900"
                  >
                    <MailIcon className="mr-2 w-5" /> Einladen
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </>
      ) : null}
      <div className="flex">
        <Sidebar />
        <div className="flex flex-grow justify-center">
          <div className="mt-12 w-2/3">
            <div className="flex items-center justify-between">
              <h1 className="flex text-4xl">
                <UserGroupIcon className="mr-3 w-6 text-slate-400" />
                Helfer
              </h1>
              <button
                onClick={() => setModal(true)}
                className="flex items-center rounded-md bg-sky-700 px-6 py-2 text-sm text-white transition-all hover:bg-sky-800 hover:shadow-xl active:bg-sky-900"
              >
                <PlusIcon className="mr-2 w-5" /> Helfer einladen
              </button>
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div className="mt-16">
                <p className="text-sm font-bold uppercase text-slate-500">
                  {users.length} Einträge
                </p>
                <div className="flex flex-col">
                  <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                      <div className="overflow-hidden shadow-md sm:rounded-lg">
                        <table className="min-w-full ">
                          <thead className="bg-zinc-100 ">
                            <tr>
                              <th
                                scope="col"
                                className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
                              >
                                Qualifikationen
                              </th>
                              <th
                                scope="col"
                                className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
                              >
                                Führerschein
                              </th>
                              <th
                                scope="col"
                                className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700"
                              >
                                Alter
                              </th>
                              <th scope="col" className="relative py-3 px-6">
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {users.map((user) => {
                              let birthDate = new Date(
                                user.birthyear ?? 0,
                                user.birthmonth + 1 ?? 0,
                                user.birthday ?? 0,
                              );

                              let ageString = "";
                              let driversLicenses = "";

                              user.DriverslicenseOnUsers?.forEach((element) => {
                                driversLicenses +=
                                  element.driverslicense.short + ", ";
                              });

                              if (user.birthday) {
                                let difference =
                                  Date.now() - birthDate.getTime();
                                let ageDate = new Date(difference);
                                let calculatedAge = Math.abs(
                                  ageDate.getUTCFullYear() - 1970,
                                );

                                ageString =
                                  calculatedAge +
                                  " (" +
                                  user.birthday +
                                  "." +
                                  user.birthmonth +
                                  "." +
                                  user.birthyear +
                                  ")";
                              } else {
                                ageString = "Kein Alter";
                              }

                              return (
                                <tr
                                  className="border-b bg-white odd:bg-white even:bg-gray-50"
                                  key={user.id}
                                >
                                  <td className="whitespace-nowrap py-4 px-6 text-sm font-medium text-gray-900 ">
                                    {user.lastname}
                                    {", "}
                                    <span className="text-slate-500">
                                      {user.firstname}
                                    </span>
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 ">
                                    -
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 ">
                                    {driversLicenses}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-6 text-sm text-gray-500 ">
                                    {ageString}
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-6 text-right text-sm font-medium">
                                    {user.organizations!.find(
                                      (orga) => orga.organizationId === orgaId,
                                    )!.state === "pending" ? (
                                      <div className="flex items-center justify-end align-middle text-slate-400">
                                        <ClockIcon className="mr-2 h-5" />
                                        Ausstehend
                                      </div>
                                    ) : (
                                      <Link
                                        to={`${user.id}`}
                                        className="text-blue-600 hover:text-blue-900 "
                                      >
                                        Details
                                      </Link>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Users;
