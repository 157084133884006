export default function Copyclaim() {
  const currentYear = new Date().getFullYear();
  return (
    <p className="text-slate-400 text-sm my-5">
      &copy; {currentYear} -{" "}
      <a href="https://einsatz-status.net" className="underline">
        EinsatzSysteme
      </a>
    </p>
  );
}
