import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import Login from "./login/Login";
import ForgotPassword from "./login/ForgotPassword";
import OrgaSelection from "./login/OrgaSelection";
import NotFound from "./misc/NotFound";
import Dashboard from "./orga/Dashboard";
import Alarmlists from "./orga/alarmlists/Alarmlists";
import RequireAuth from "./services/requireAuth.service";
import Users from "./orga/users/Users";
import Radios from "./orga/Radios";
import CreateAlarm from "./orga/alarm/CreateAlarm";
import CreateAlarmlist from "./orga/alarmlists/CreateAlarmlist";
import EditAlarmlist from "./orga/alarmlists/EditAlarmlist";
import OrgaSettings from "./orga/settings/OrgaSettings";
import "react-toastify/dist/ReactToastify.min.css";
import UserDetail from "./orga/users/UserDetail";
import AlarmPage from "./orga/alarm/AlarmPage";
import AlarmDetail from "./orga/alarm/AlarmDetail";
import CreateAAO from "./orga/settings/tabs/aao/CreateAAO";
import EditAAO from "./orga/settings/tabs/aao/EditAAO";
import Profile from "./profile/Profile";
import Verify from "./login/Verify";
import Register from "./login/register/Register";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="login" element={<Login />} />
          <Route path="verify/:otp" element={<Verify />} />
          <Route path="register" element={<Register />} />
          <Route path="forgotPassword" element={<ForgotPassword />} />
          <Route
            path="profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="orga"
            element={
              <RequireAuth>
                <OrgaSelection />
              </RequireAuth>
            }
          />
          <Route
            path="orga/:orgaId/"
            element={
              <RequireAuth>
                <App />
              </RequireAuth>
            }
          >
            <Route path="dashboard" element={<Dashboard />} />

            <Route path="users/:userId" element={<UserDetail />} />
            <Route path="users" element={<Users />} />
            <Route path="radios" element={<Radios />} />
            <Route path="alarmlist" element={<Alarmlists />} />
            <Route
              path="alarmlist/createAlarmlist"
              element={<CreateAlarmlist />}
            />
            <Route path="alarmlist/:alarmlistId" element={<EditAlarmlist />} />
            <Route path="alarm" element={<AlarmPage />} />
            <Route path="alarm/:alarmId" element={<AlarmDetail />} />
            <Route path="alarm/createAlarm" element={<CreateAlarm />} />
            <Route path="aao" />
            <Route path="aao/createAAO" element={<CreateAAO />} />
            <Route path="aao/:aaoId" element={<EditAAO />} />
            <Route path="settings" element={<OrgaSettings />}></Route>
          </Route>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
